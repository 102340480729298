type Environment = "development" | "production" | "staging" | "qa";

interface Config { // Add any other environment depending fields
  env: Environment;
  commit: string;
  rollbarAccessToken: string;
  baseURL: string;
  wsURL: string;
  cookieDomain: string;
  collabServer: string;
  schemaVersion: string;
  formulaMentionDefaultDateColumn: string
  formulaMentionComputeDateColumn: string
  CLOSING_DATE_KEY: string
}

const BaseURLS: Record<Environment, string> = {
  development: "http://localhost:8080",
  production: "https://backend.cofi.ai",
  staging: "https://staging.cofi.ai",
  qa: "https://qa-backend.cofi.ai"
};
const wsURLS: Record<Environment, string> = {
  development: "ws://localhost:8080",
  production: "wss://backend.cofi.ai",
  staging: "wss://staging.cofi.ai",
  qa: "wss://qa-backend.cofi.ai"
};
const CookieDomains: Record<Environment, string> = {
  development: "localhost",
  staging: "cofi.ai",
  production: "cofi.ai",
  qa: "cofi.ai"
};
const CollaborationServer: Record<Environment, string> = {
  development: "ws://localhost:9000/ws",
  production: "wss://backend.cofi.ai/ws",
  staging: "wss://staging.cofi.ai/ws",
  qa: "wss://qa-backend.cofi.ai/ws"
};

const {
  MODE = "development",
  VITE_HASH_COMMIT = "",
  VITE_ROLLBAR_ACCESS_TOKEN = "",
  FORMULA_MENTION_DEFAULT_DATE_COLUMN = "__default_date__",
  FORMULA_MENTION_COMPUTE_DATE_COLUMN = "__compute_date__",
  CLOSING_DATE_KEY = "__closing_date__"
} = import.meta.env;

const env = MODE as Environment;
const commit = VITE_HASH_COMMIT as string;
const rollbarAccessToken = VITE_ROLLBAR_ACCESS_TOKEN as string;
const formulaMentionDefaultDateColumn = FORMULA_MENTION_DEFAULT_DATE_COLUMN;
const formulaMentionComputeDateColumn = FORMULA_MENTION_COMPUTE_DATE_COLUMN;

export const useConfig = (): Config => ({
  env,
  commit,
  rollbarAccessToken,
  baseURL: BaseURLS[env],
  wsURL: wsURLS[env],
  cookieDomain: CookieDomains[env],
  collabServer: CollaborationServer[env],
  formulaMentionDefaultDateColumn,
  formulaMentionComputeDateColumn,
  schemaVersion: "4.0",
  CLOSING_DATE_KEY
});
