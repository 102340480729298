/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimplifiedTable
 */
export interface SimplifiedTable {
    /**
     * 
     * @type {string}
     * @memberof SimplifiedTable
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedTable
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedTable
     */
    tableType: SimplifiedTableTableTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedTable
     */
    dashboardUuid: string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedTable
     */
    dashboardEmoji?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedTable
     */
    dashboardName: string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedTable
     */
    workspaceUuid: string;
}


/**
 * @export
 */
export const SimplifiedTableTableTypeEnum = {
    Empty: '',
    PivotTable: 'pivotTable',
    ReportTable: 'reportTable',
    Comparative: 'comparative',
    Projection: 'projection',
    Histogram: 'histogram',
    Pie: 'pie',
    Treemap: 'treemap',
    Bridge: 'bridge',
    Model: 'model',
    ModelingTable: 'modelingTable'
} as const;
export type SimplifiedTableTableTypeEnum = typeof SimplifiedTableTableTypeEnum[keyof typeof SimplifiedTableTableTypeEnum];


/**
 * Check if a given object implements the SimplifiedTable interface.
 */
export function instanceOfSimplifiedTable(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('title' in value)) return false;
    if (!('tableType' in value)) return false;
    if (!('dashboardUuid' in value)) return false;
    if (!('dashboardName' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    return true;
}

export function SimplifiedTableFromJSON(json: any): SimplifiedTable {
    return SimplifiedTableFromJSONTyped(json, false);
}

export function SimplifiedTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimplifiedTable {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'title': json['title'],
        'tableType': json['table_type'],
        'dashboardUuid': json['dashboard_uuid'],
        'dashboardEmoji': json['dashboard_emoji'] == null ? undefined : json['dashboard_emoji'],
        'dashboardName': json['dashboard_name'],
        'workspaceUuid': json['workspace_uuid'],
    };
}

export function SimplifiedTableToJSON(value?: SimplifiedTable | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'title': value['title'],
        'table_type': value['tableType'],
        'dashboard_uuid': value['dashboardUuid'],
        'dashboard_emoji': value['dashboardEmoji'],
        'dashboard_name': value['dashboardName'],
        'workspace_uuid': value['workspaceUuid'],
    };
}

