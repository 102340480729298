import { ApiResponse } from "~/gen";

export function getFilenameFromRawResponse(response: ApiResponse<any>) {
  const headers = response.raw.headers;
  const contentDispositionHeader = headers.get('content-disposition');
  const regex = /filename="([^"]+)"/;
  const match = contentDispositionHeader?.match(regex);
  if (match) return match[1]
}

export function useDownload(blob: Blob, name: string) {
  const anchor = document.createElement("a");
  const url = URL.createObjectURL(blob);

  anchor.setAttribute("href", url);
  anchor.setAttribute("download", name);
  anchor.style.visibility = "hidden";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
