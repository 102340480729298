/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportTableRemapParams
 */
export interface ImportTableRemapParams {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ImportTableRemapParams
     */
    entities?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ImportTableRemapParams
     */
    dimensions?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ImportTableRemapParams
     */
    scenarios?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ImportTableRemapParams interface.
 */
export function instanceOfImportTableRemapParams(value: object): boolean {
    return true;
}

export function ImportTableRemapParamsFromJSON(json: any): ImportTableRemapParams {
    return ImportTableRemapParamsFromJSONTyped(json, false);
}

export function ImportTableRemapParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportTableRemapParams {
    if (json == null) {
        return json;
    }
    return {
        
        'entities': json['entities'] == null ? undefined : json['entities'],
        'dimensions': json['dimensions'] == null ? undefined : json['dimensions'],
        'scenarios': json['scenarios'] == null ? undefined : json['scenarios'],
    };
}

export function ImportTableRemapParamsToJSON(value?: ImportTableRemapParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entities': value['entities'],
        'dimensions': value['dimensions'],
        'scenarios': value['scenarios'],
    };
}

