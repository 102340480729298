/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportExpressionsParams
 */
export interface ImportExpressionsParams {
    /**
     * 
     * @type {string}
     * @memberof ImportExpressionsParams
     */
    originScenarioUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ImportExpressionsParams
     */
    targetScenarioUuid: string;
}

/**
 * Check if a given object implements the ImportExpressionsParams interface.
 */
export function instanceOfImportExpressionsParams(value: object): boolean {
    if (!('originScenarioUuid' in value)) return false;
    if (!('targetScenarioUuid' in value)) return false;
    return true;
}

export function ImportExpressionsParamsFromJSON(json: any): ImportExpressionsParams {
    return ImportExpressionsParamsFromJSONTyped(json, false);
}

export function ImportExpressionsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportExpressionsParams {
    if (json == null) {
        return json;
    }
    return {
        
        'originScenarioUuid': json['origin_scenario_uuid'],
        'targetScenarioUuid': json['target_scenario_uuid'],
    };
}

export function ImportExpressionsParamsToJSON(value?: ImportExpressionsParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'origin_scenario_uuid': value['originScenarioUuid'],
        'target_scenario_uuid': value['targetScenarioUuid'],
    };
}

