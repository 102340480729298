/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Attachment,
  HTTPValidationError,
  UpdateAttachmentParams,
} from '../models/index';
import {
    AttachmentFromJSON,
    AttachmentToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    UpdateAttachmentParamsFromJSON,
    UpdateAttachmentParamsToJSON,
} from '../models/index';

export interface AddAttachmentToEntityRequest {
    entityUuid: string;
    workspaceCode: string;
    name: string;
    description?: string;
    link?: string;
    file?: Blob;
}

export interface DeleteAttachmentRequest {
    entityUuid: string;
    attachmentUuid: string;
    workspaceCode: string;
}

export interface DownloadAttachmentFileRequest {
    entityUuid: string;
    attachmentUuid: string;
    workspaceCode: string;
}

export interface GetEntityAttachmentsRequest {
    entityUuid: string;
    workspaceCode: string;
}

export interface UpdateAttachmentRequest {
    entityUuid: string;
    attachmentUuid: string;
    workspaceCode: string;
    updateAttachmentParams: UpdateAttachmentParams;
}

/**
 * 
 */
export class AttachmentsApi extends runtime.BaseAPI {

    /**
     * Add Attachment To Entity
     */
    async addAttachmentToEntityRaw(requestParameters: AddAttachmentToEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters['entityUuid'] == null) {
            throw new runtime.RequiredError(
                'entityUuid',
                'Required parameter "entityUuid" was null or undefined when calling addAttachmentToEntity().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addAttachmentToEntity().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling addAttachmentToEntity().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['name'] != null) {
            formParams.append('name', requestParameters['name'] as any);
        }

        if (requestParameters['description'] != null) {
            formParams.append('description', requestParameters['description'] as any);
        }

        if (requestParameters['link'] != null) {
            formParams.append('link', requestParameters['link'] as any);
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/{workspace_code}/attachments/{entity_uuid}`.replace(`{${"entity_uuid"}}`, encodeURIComponent(String(requestParameters['entityUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * Add Attachment To Entity
     */
    async addAttachmentToEntity(requestParameters: AddAttachmentToEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Attachment> {
        const response = await this.addAttachmentToEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Attachment
     */
    async deleteAttachmentRaw(requestParameters: DeleteAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['entityUuid'] == null) {
            throw new runtime.RequiredError(
                'entityUuid',
                'Required parameter "entityUuid" was null or undefined when calling deleteAttachment().'
            );
        }

        if (requestParameters['attachmentUuid'] == null) {
            throw new runtime.RequiredError(
                'attachmentUuid',
                'Required parameter "attachmentUuid" was null or undefined when calling deleteAttachment().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteAttachment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/attachments/{entity_uuid}/attachment/{attachment_uuid}`.replace(`{${"entity_uuid"}}`, encodeURIComponent(String(requestParameters['entityUuid']))).replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(requestParameters['attachmentUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Attachment
     */
    async deleteAttachment(requestParameters: DeleteAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download Attachment File
     */
    async downloadAttachmentFileRaw(requestParameters: DownloadAttachmentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['entityUuid'] == null) {
            throw new runtime.RequiredError(
                'entityUuid',
                'Required parameter "entityUuid" was null or undefined when calling downloadAttachmentFile().'
            );
        }

        if (requestParameters['attachmentUuid'] == null) {
            throw new runtime.RequiredError(
                'attachmentUuid',
                'Required parameter "attachmentUuid" was null or undefined when calling downloadAttachmentFile().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadAttachmentFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/attachments/{entity_uuid}/attachment/{attachment_uuid}/download`.replace(`{${"entity_uuid"}}`, encodeURIComponent(String(requestParameters['entityUuid']))).replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(requestParameters['attachmentUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download Attachment File
     */
    async downloadAttachmentFile(requestParameters: DownloadAttachmentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadAttachmentFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Entity Attachments
     */
    async getEntityAttachmentsRaw(requestParameters: GetEntityAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Attachment>>> {
        if (requestParameters['entityUuid'] == null) {
            throw new runtime.RequiredError(
                'entityUuid',
                'Required parameter "entityUuid" was null or undefined when calling getEntityAttachments().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling getEntityAttachments().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/attachments/{entity_uuid}`.replace(`{${"entity_uuid"}}`, encodeURIComponent(String(requestParameters['entityUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentFromJSON));
    }

    /**
     * Get Entity Attachments
     */
    async getEntityAttachments(requestParameters: GetEntityAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Attachment>> {
        const response = await this.getEntityAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Attachment
     */
    async updateAttachmentRaw(requestParameters: UpdateAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['entityUuid'] == null) {
            throw new runtime.RequiredError(
                'entityUuid',
                'Required parameter "entityUuid" was null or undefined when calling updateAttachment().'
            );
        }

        if (requestParameters['attachmentUuid'] == null) {
            throw new runtime.RequiredError(
                'attachmentUuid',
                'Required parameter "attachmentUuid" was null or undefined when calling updateAttachment().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateAttachment().'
            );
        }

        if (requestParameters['updateAttachmentParams'] == null) {
            throw new runtime.RequiredError(
                'updateAttachmentParams',
                'Required parameter "updateAttachmentParams" was null or undefined when calling updateAttachment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/attachments/{entity_uuid}/attachment/{attachment_uuid}`.replace(`{${"entity_uuid"}}`, encodeURIComponent(String(requestParameters['entityUuid']))).replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(requestParameters['attachmentUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAttachmentParamsToJSON(requestParameters['updateAttachmentParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Attachment
     */
    async updateAttachment(requestParameters: UpdateAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
