/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface PivotTableParams
 */
export interface PivotTableParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof PivotTableParams
     */
    dimensions?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof PivotTableParams
     */
    filters?: Array<Filter>;
    /**
     * 
     * @type {Period}
     * @memberof PivotTableParams
     */
    period: Period;
    /**
     * 
     * @type {boolean}
     * @memberof PivotTableParams
     */
    refresh?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PivotTableParams
     */
    yearTotals?: PivotTableParamsYearTotalsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PivotTableParams
     */
    onlyDerived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotTableParams
     */
    onlyBase?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PivotTableParams
     */
    snapshotUuid?: string;
}


/**
 * @export
 */
export const PivotTableParamsYearTotalsEnum = {
    Natural: 'natural',
    Fiscal: 'fiscal',
    Null: 'null'
} as const;
export type PivotTableParamsYearTotalsEnum = typeof PivotTableParamsYearTotalsEnum[keyof typeof PivotTableParamsYearTotalsEnum];


/**
 * Check if a given object implements the PivotTableParams interface.
 */
export function instanceOfPivotTableParams(value: object): boolean {
    if (!('period' in value)) return false;
    return true;
}

export function PivotTableParamsFromJSON(json: any): PivotTableParams {
    return PivotTableParamsFromJSONTyped(json, false);
}

export function PivotTableParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTableParams {
    if (json == null) {
        return json;
    }
    return {
        
        'dimensions': json['dimensions'] == null ? undefined : json['dimensions'],
        'filters': json['filters'] == null ? undefined : ((json['filters'] as Array<any>).map(FilterFromJSON)),
        'period': PeriodFromJSON(json['period']),
        'refresh': json['refresh'] == null ? undefined : json['refresh'],
        'yearTotals': json['year_totals'] == null ? undefined : json['year_totals'],
        'onlyDerived': json['only_derived'] == null ? undefined : json['only_derived'],
        'onlyBase': json['only_base'] == null ? undefined : json['only_base'],
        'snapshotUuid': json['snapshot_uuid'] == null ? undefined : json['snapshot_uuid'],
    };
}

export function PivotTableParamsToJSON(value?: PivotTableParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dimensions': value['dimensions'],
        'filters': value['filters'] == null ? undefined : ((value['filters'] as Array<any>).map(FilterToJSON)),
        'period': PeriodToJSON(value['period']),
        'refresh': value['refresh'],
        'year_totals': value['yearTotals'],
        'only_derived': value['onlyDerived'],
        'only_base': value['onlyBase'],
        'snapshot_uuid': value['snapshotUuid'],
    };
}

