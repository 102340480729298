/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddExpressionToVariableParams,
  AddVariableToModelParams,
  CofiApiRoutersModelsFavoriteParams,
  CreateModelParams,
  CreateModelVersionParams,
  DuplicateModelParams,
  HTTPValidationError,
  ImportExpressionsParams,
  Model,
  ModelTableData,
  ModelVersion,
  RemoveModelVariableDimensionValueParams,
  SetVariableDimensionsParams,
  UpdateModelContributorsParams,
  UpdateModelDimensionValuesParams,
  UpdateModelParams,
  UpdateModelPeriodGrainParams,
  UpdateModelPeriodParams,
  UpdateModelVariableDimensionValueParams,
  UpdateModelVariableParams,
  UpdateModelVersionContributorsParams,
  UpdateModelVersionParams,
  UpdateVariableExpressionPeriodParams,
  ValidateExpressionParams,
  Variable,
  VariableDrillDownParams,
} from '../models/index';
import {
    AddExpressionToVariableParamsFromJSON,
    AddExpressionToVariableParamsToJSON,
    AddVariableToModelParamsFromJSON,
    AddVariableToModelParamsToJSON,
    CofiApiRoutersModelsFavoriteParamsFromJSON,
    CofiApiRoutersModelsFavoriteParamsToJSON,
    CreateModelParamsFromJSON,
    CreateModelParamsToJSON,
    CreateModelVersionParamsFromJSON,
    CreateModelVersionParamsToJSON,
    DuplicateModelParamsFromJSON,
    DuplicateModelParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ImportExpressionsParamsFromJSON,
    ImportExpressionsParamsToJSON,
    ModelFromJSON,
    ModelToJSON,
    ModelTableDataFromJSON,
    ModelTableDataToJSON,
    ModelVersionFromJSON,
    ModelVersionToJSON,
    RemoveModelVariableDimensionValueParamsFromJSON,
    RemoveModelVariableDimensionValueParamsToJSON,
    SetVariableDimensionsParamsFromJSON,
    SetVariableDimensionsParamsToJSON,
    UpdateModelContributorsParamsFromJSON,
    UpdateModelContributorsParamsToJSON,
    UpdateModelDimensionValuesParamsFromJSON,
    UpdateModelDimensionValuesParamsToJSON,
    UpdateModelParamsFromJSON,
    UpdateModelParamsToJSON,
    UpdateModelPeriodGrainParamsFromJSON,
    UpdateModelPeriodGrainParamsToJSON,
    UpdateModelPeriodParamsFromJSON,
    UpdateModelPeriodParamsToJSON,
    UpdateModelVariableDimensionValueParamsFromJSON,
    UpdateModelVariableDimensionValueParamsToJSON,
    UpdateModelVariableParamsFromJSON,
    UpdateModelVariableParamsToJSON,
    UpdateModelVersionContributorsParamsFromJSON,
    UpdateModelVersionContributorsParamsToJSON,
    UpdateModelVersionParamsFromJSON,
    UpdateModelVersionParamsToJSON,
    UpdateVariableExpressionPeriodParamsFromJSON,
    UpdateVariableExpressionPeriodParamsToJSON,
    ValidateExpressionParamsFromJSON,
    ValidateExpressionParamsToJSON,
    VariableFromJSON,
    VariableToJSON,
    VariableDrillDownParamsFromJSON,
    VariableDrillDownParamsToJSON,
} from '../models/index';

export interface AddExpressionToVariableRequest {
    modelUuid: string;
    variableUuid: string;
    workspaceCode: string;
    addExpressionToVariableParams: AddExpressionToVariableParams;
}

export interface AddVariableDimensionValueRequest {
    workspaceCode: string;
    modelUuid: string;
    variableUuid: string;
    dimensionUuid: string;
    updateModelVariableDimensionValueParams: UpdateModelVariableDimensionValueParams;
}

export interface AddVariableToModelRequest {
    modelUuid: string;
    workspaceCode: string;
    addVariableToModelParams: AddVariableToModelParams;
}

export interface ComputeVariableDrillDownRequest {
    variableUuid: string;
    workspaceCode: string;
    variableDrillDownParams: VariableDrillDownParams;
}

export interface CreateModelRequest {
    workspaceCode: string;
    createModelParams: CreateModelParams;
}

export interface CreateWorkspaceModelsVersionRequest {
    modelUuid: string;
    workspaceCode: string;
    createModelVersionParams: CreateModelVersionParams;
}

export interface DeleteExpressionRequest {
    modelUuid: string;
    variableUuid: string;
    expressionUuid: string;
    workspaceCode: string;
}

export interface DeleteModelRequest {
    modelUuid: string;
    workspaceCode: string;
}

export interface DeleteModelVariableRequest {
    modelUuid: string;
    variableUuid: string;
    workspaceCode: string;
}

export interface DeleteModelsVersionRequest {
    modelUuid: string;
    versionUuid: string;
    workspaceCode: string;
}

export interface DuplicateModelRequest {
    modelUuid: string;
    workspaceCode: string;
    duplicateModelParams: DuplicateModelParams;
}

export interface FetchModelDetailsRequest {
    modelUuid: string;
    workspaceCode: string;
}

export interface FetchModelTableDataRequest {
    modelUuid: string;
    workspaceCode: string;
    scenarioUuid: string;
    snapshotUuid?: string;
    refresh?: boolean;
}

export interface FetchWorkspaceModelsRequest {
    workspaceCode: string;
}

export interface ImportExpressionsFromScenarioRequest {
    modelUuid: string;
    workspaceCode: string;
    importExpressionsParams: ImportExpressionsParams;
}

export interface PublishModelVersionRequest {
    modelUuid: string;
    versionUuid: string;
    workspaceCode: string;
}

export interface RemoveVariableDimensionValueRequest {
    workspaceCode: string;
    modelUuid: string;
    variableUuid: string;
    dimensionUuid: string;
    removeModelVariableDimensionValueParams: RemoveModelVariableDimensionValueParams;
}

export interface SaveVersionChangesRequest {
    modelUuid: string;
    parentVersionUuid: string;
    workspaceCode: string;
}

export interface SetModelFavoriteStatusRequest {
    workspaceCode: string;
    cofiApiRoutersModelsFavoriteParams: CofiApiRoutersModelsFavoriteParams;
}

export interface SetVariableDimensionsRequest {
    modelUuid: string;
    variableUuid: string;
    workspaceCode: string;
    setVariableDimensionsParams: SetVariableDimensionsParams;
}

export interface UnpublishModelVersionRequest {
    modelUuid: string;
    versionUuid: string;
    workspaceCode: string;
}

export interface UpdateExpressionPeriodEndRequest {
    modelUuid: string;
    variableUuid: string;
    expressionUuid: string;
    workspaceCode: string;
    updateVariableExpressionPeriodParams: UpdateVariableExpressionPeriodParams;
}

export interface UpdateModelContributorsRequest {
    modelUuid: string;
    workspaceCode: string;
    updateModelContributorsParams: UpdateModelContributorsParams;
}

export interface UpdateModelDimensionValuesRequest {
    workspaceCode: string;
    modelUuid: string;
    dimensionUuid: string;
    updateModelDimensionValuesParams: UpdateModelDimensionValuesParams;
}

export interface UpdateModelPeriodRequest {
    modelUuid: string;
    workspaceCode: string;
    updateModelPeriodParams: UpdateModelPeriodParams;
}

export interface UpdateModelPeriodGrainRequest {
    modelUuid: string;
    workspaceCode: string;
    updateModelPeriodGrainParams: UpdateModelPeriodGrainParams;
}

export interface UpdateModelPropertiesRequest {
    modelUuid: string;
    workspaceCode: string;
    updateModelParams: UpdateModelParams;
}

export interface UpdateModelVariableRequest {
    modelUuid: string;
    variableUuid: string;
    workspaceCode: string;
    updateModelVariableParams: UpdateModelVariableParams;
}

export interface UpdateModelVersionRequest {
    modelUuid: string;
    versionUuid: string;
    workspaceCode: string;
    updateModelVersionParams: UpdateModelVersionParams;
}

export interface UpdateModelVersionContributorsRequest {
    modelUuid: string;
    versionUuid: string;
    workspaceCode: string;
    updateModelVersionContributorsParams: UpdateModelVersionContributorsParams;
}

export interface UpdateVariableDimensionValueRequest {
    workspaceCode: string;
    modelUuid: string;
    variableUuid: string;
    dimensionUuid: string;
    updateModelVariableDimensionValueParams: UpdateModelVariableDimensionValueParams;
}

export interface ValidateExpressionRequest {
    modelUuid: string;
    variableUuid: string;
    workspaceCode: string;
    validateExpressionParams: ValidateExpressionParams;
}

/**
 * 
 */
export class ModelsApi extends runtime.BaseAPI {

    /**
     * Add Expression To Variable
     */
    async addExpressionToVariableRaw(requestParameters: AddExpressionToVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling addExpressionToVariable().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling addExpressionToVariable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addExpressionToVariable().'
            );
        }

        if (requestParameters['addExpressionToVariableParams'] == null) {
            throw new runtime.RequiredError(
                'addExpressionToVariableParams',
                'Required parameter "addExpressionToVariableParams" was null or undefined when calling addExpressionToVariable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/variable/{variable_uuid}/expressions`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddExpressionToVariableParamsToJSON(requestParameters['addExpressionToVariableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Add Expression To Variable
     */
    async addExpressionToVariable(requestParameters: AddExpressionToVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.addExpressionToVariableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Dimension Value To Variable
     */
    async addVariableDimensionValueRaw(requestParameters: AddVariableDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Variable>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addVariableDimensionValue().'
            );
        }

        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling addVariableDimensionValue().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling addVariableDimensionValue().'
            );
        }

        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling addVariableDimensionValue().'
            );
        }

        if (requestParameters['updateModelVariableDimensionValueParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelVariableDimensionValueParams',
                'Required parameter "updateModelVariableDimensionValueParams" was null or undefined when calling addVariableDimensionValue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/models/{model_uuid}/variables/{variable_uuid}/dimensions/{dimension_uuid}`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelVariableDimensionValueParamsToJSON(requestParameters['updateModelVariableDimensionValueParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableFromJSON(jsonValue));
    }

    /**
     * Add Dimension Value To Variable
     */
    async addVariableDimensionValue(requestParameters: AddVariableDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Variable> {
        const response = await this.addVariableDimensionValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Variable To Model
     */
    async addVariableToModelRaw(requestParameters: AddVariableToModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Variable>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling addVariableToModel().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addVariableToModel().'
            );
        }

        if (requestParameters['addVariableToModelParams'] == null) {
            throw new runtime.RequiredError(
                'addVariableToModelParams',
                'Required parameter "addVariableToModelParams" was null or undefined when calling addVariableToModel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/variable`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddVariableToModelParamsToJSON(requestParameters['addVariableToModelParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableFromJSON(jsonValue));
    }

    /**
     * Add Variable To Model
     */
    async addVariableToModel(requestParameters: AddVariableToModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Variable> {
        const response = await this.addVariableToModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Drilldown
     */
    async computeVariableDrillDownRaw(requestParameters: ComputeVariableDrillDownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling computeVariableDrillDown().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling computeVariableDrillDown().'
            );
        }

        if (requestParameters['variableDrillDownParams'] == null) {
            throw new runtime.RequiredError(
                'variableDrillDownParams',
                'Required parameter "variableDrillDownParams" was null or undefined when calling computeVariableDrillDown().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/drilldown/{variable_uuid}`.replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariableDrillDownParamsToJSON(requestParameters['variableDrillDownParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Drilldown
     */
    async computeVariableDrillDown(requestParameters: ComputeVariableDrillDownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.computeVariableDrillDownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create New Model
     */
    async createModelRaw(requestParameters: CreateModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createModel().'
            );
        }

        if (requestParameters['createModelParams'] == null) {
            throw new runtime.RequiredError(
                'createModelParams',
                'Required parameter "createModelParams" was null or undefined when calling createModel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateModelParamsToJSON(requestParameters['createModelParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Create New Model
     */
    async createModel(requestParameters: CreateModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.createModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Model Version
     */
    async createWorkspaceModelsVersionRaw(requestParameters: CreateWorkspaceModelsVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelVersion>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling createWorkspaceModelsVersion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createWorkspaceModelsVersion().'
            );
        }

        if (requestParameters['createModelVersionParams'] == null) {
            throw new runtime.RequiredError(
                'createModelVersionParams',
                'Required parameter "createModelVersionParams" was null or undefined when calling createWorkspaceModelsVersion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/versions`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateModelVersionParamsToJSON(requestParameters['createModelVersionParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelVersionFromJSON(jsonValue));
    }

    /**
     * Create Model Version
     */
    async createWorkspaceModelsVersion(requestParameters: CreateWorkspaceModelsVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelVersion> {
        const response = await this.createWorkspaceModelsVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Variable Expression
     */
    async deleteExpressionRaw(requestParameters: DeleteExpressionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling deleteExpression().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling deleteExpression().'
            );
        }

        if (requestParameters['expressionUuid'] == null) {
            throw new runtime.RequiredError(
                'expressionUuid',
                'Required parameter "expressionUuid" was null or undefined when calling deleteExpression().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteExpression().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/variables/{variable_uuid}/expressions/{expression_uuid}`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"expression_uuid"}}`, encodeURIComponent(String(requestParameters['expressionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Delete Variable Expression
     */
    async deleteExpression(requestParameters: DeleteExpressionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.deleteExpressionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Model
     */
    async deleteModelRaw(requestParameters: DeleteModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling deleteModel().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteModel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Model
     */
    async deleteModel(requestParameters: DeleteModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Model Variable
     */
    async deleteModelVariableRaw(requestParameters: DeleteModelVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling deleteModelVariable().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling deleteModelVariable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteModelVariable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/variable/{variable_uuid}`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Remove Model Variable
     */
    async deleteModelVariable(requestParameters: DeleteModelVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.deleteModelVariableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Model Version
     */
    async deleteModelsVersionRaw(requestParameters: DeleteModelsVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling deleteModelsVersion().'
            );
        }

        if (requestParameters['versionUuid'] == null) {
            throw new runtime.RequiredError(
                'versionUuid',
                'Required parameter "versionUuid" was null or undefined when calling deleteModelsVersion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteModelsVersion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/versions/{version_uuid}`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"version_uuid"}}`, encodeURIComponent(String(requestParameters['versionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Delete Model Version
     */
    async deleteModelsVersion(requestParameters: DeleteModelsVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.deleteModelsVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate Model
     */
    async duplicateModelRaw(requestParameters: DuplicateModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling duplicateModel().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling duplicateModel().'
            );
        }

        if (requestParameters['duplicateModelParams'] == null) {
            throw new runtime.RequiredError(
                'duplicateModelParams',
                'Required parameter "duplicateModelParams" was null or undefined when calling duplicateModel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/duplicate`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicateModelParamsToJSON(requestParameters['duplicateModelParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Duplicate Model
     */
    async duplicateModel(requestParameters: DuplicateModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.duplicateModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Model
     */
    async fetchModelDetailsRaw(requestParameters: FetchModelDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling fetchModelDetails().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchModelDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Fetch Model
     */
    async fetchModelDetails(requestParameters: FetchModelDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.fetchModelDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Model Table Data
     */
    async fetchModelTableDataRaw(requestParameters: FetchModelTableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelTableData>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling fetchModelTableData().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchModelTableData().'
            );
        }

        if (requestParameters['scenarioUuid'] == null) {
            throw new runtime.RequiredError(
                'scenarioUuid',
                'Required parameter "scenarioUuid" was null or undefined when calling fetchModelTableData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['scenarioUuid'] != null) {
            queryParameters['scenario_uuid'] = requestParameters['scenarioUuid'];
        }

        if (requestParameters['snapshotUuid'] != null) {
            queryParameters['snapshot_uuid'] = requestParameters['snapshotUuid'];
        }

        if (requestParameters['refresh'] != null) {
            queryParameters['refresh'] = requestParameters['refresh'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/table`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelTableDataFromJSON(jsonValue));
    }

    /**
     * Fetch Model Table Data
     */
    async fetchModelTableData(requestParameters: FetchModelTableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelTableData> {
        const response = await this.fetchModelTableDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Workspace Models
     */
    async fetchWorkspaceModelsRaw(requestParameters: FetchWorkspaceModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Model>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchWorkspaceModels().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelFromJSON));
    }

    /**
     * Fetch Workspace Models
     */
    async fetchWorkspaceModels(requestParameters: FetchWorkspaceModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Model>> {
        const response = await this.fetchWorkspaceModelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import Expressions From Scenario
     */
    async importExpressionsFromScenarioRaw(requestParameters: ImportExpressionsFromScenarioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling importExpressionsFromScenario().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling importExpressionsFromScenario().'
            );
        }

        if (requestParameters['importExpressionsParams'] == null) {
            throw new runtime.RequiredError(
                'importExpressionsParams',
                'Required parameter "importExpressionsParams" was null or undefined when calling importExpressionsFromScenario().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/expressions/import`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportExpressionsParamsToJSON(requestParameters['importExpressionsParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Import Expressions From Scenario
     */
    async importExpressionsFromScenario(requestParameters: ImportExpressionsFromScenarioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.importExpressionsFromScenarioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish Version
     */
    async publishModelVersionRaw(requestParameters: PublishModelVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling publishModelVersion().'
            );
        }

        if (requestParameters['versionUuid'] == null) {
            throw new runtime.RequiredError(
                'versionUuid',
                'Required parameter "versionUuid" was null or undefined when calling publishModelVersion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling publishModelVersion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/versions/{version_uuid}/publish`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"version_uuid"}}`, encodeURIComponent(String(requestParameters['versionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Publish Version
     */
    async publishModelVersion(requestParameters: PublishModelVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.publishModelVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Model Variable Dimension Value
     */
    async removeVariableDimensionValueRaw(requestParameters: RemoveVariableDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Variable>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeVariableDimensionValue().'
            );
        }

        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling removeVariableDimensionValue().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling removeVariableDimensionValue().'
            );
        }

        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling removeVariableDimensionValue().'
            );
        }

        if (requestParameters['removeModelVariableDimensionValueParams'] == null) {
            throw new runtime.RequiredError(
                'removeModelVariableDimensionValueParams',
                'Required parameter "removeModelVariableDimensionValueParams" was null or undefined when calling removeVariableDimensionValue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/models/{model_uuid}/variables/{variable_uuid}/dimensions/{dimension_uuid}/remove`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveModelVariableDimensionValueParamsToJSON(requestParameters['removeModelVariableDimensionValueParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableFromJSON(jsonValue));
    }

    /**
     * Remove Model Variable Dimension Value
     */
    async removeVariableDimensionValue(requestParameters: RemoveVariableDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Variable> {
        const response = await this.removeVariableDimensionValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save Version Changes
     */
    async saveVersionChangesRaw(requestParameters: SaveVersionChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling saveVersionChanges().'
            );
        }

        if (requestParameters['parentVersionUuid'] == null) {
            throw new runtime.RequiredError(
                'parentVersionUuid',
                'Required parameter "parentVersionUuid" was null or undefined when calling saveVersionChanges().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling saveVersionChanges().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/versions/{parent_version_uuid}/save`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"parent_version_uuid"}}`, encodeURIComponent(String(requestParameters['parentVersionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Save Version Changes
     */
    async saveVersionChanges(requestParameters: SaveVersionChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.saveVersionChangesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Favorite
     */
    async setModelFavoriteStatusRaw(requestParameters: SetModelFavoriteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling setModelFavoriteStatus().'
            );
        }

        if (requestParameters['cofiApiRoutersModelsFavoriteParams'] == null) {
            throw new runtime.RequiredError(
                'cofiApiRoutersModelsFavoriteParams',
                'Required parameter "cofiApiRoutersModelsFavoriteParams" was null or undefined when calling setModelFavoriteStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/favorite`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CofiApiRoutersModelsFavoriteParamsToJSON(requestParameters['cofiApiRoutersModelsFavoriteParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set Favorite
     */
    async setModelFavoriteStatus(requestParameters: SetModelFavoriteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.setModelFavoriteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Variable Dimensions
     */
    async setVariableDimensionsRaw(requestParameters: SetVariableDimensionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling setVariableDimensions().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling setVariableDimensions().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling setVariableDimensions().'
            );
        }

        if (requestParameters['setVariableDimensionsParams'] == null) {
            throw new runtime.RequiredError(
                'setVariableDimensionsParams',
                'Required parameter "setVariableDimensionsParams" was null or undefined when calling setVariableDimensions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/variable/{variable_uuid}/dimensions`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetVariableDimensionsParamsToJSON(requestParameters['setVariableDimensionsParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Set Variable Dimensions
     */
    async setVariableDimensions(requestParameters: SetVariableDimensionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.setVariableDimensionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unpublish Version
     */
    async unpublishModelVersionRaw(requestParameters: UnpublishModelVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling unpublishModelVersion().'
            );
        }

        if (requestParameters['versionUuid'] == null) {
            throw new runtime.RequiredError(
                'versionUuid',
                'Required parameter "versionUuid" was null or undefined when calling unpublishModelVersion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling unpublishModelVersion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/versions/{version_uuid}/publish`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"version_uuid"}}`, encodeURIComponent(String(requestParameters['versionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Unpublish Version
     */
    async unpublishModelVersion(requestParameters: UnpublishModelVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.unpublishModelVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Expression Period End
     */
    async updateExpressionPeriodEndRaw(requestParameters: UpdateExpressionPeriodEndRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateExpressionPeriodEnd().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling updateExpressionPeriodEnd().'
            );
        }

        if (requestParameters['expressionUuid'] == null) {
            throw new runtime.RequiredError(
                'expressionUuid',
                'Required parameter "expressionUuid" was null or undefined when calling updateExpressionPeriodEnd().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateExpressionPeriodEnd().'
            );
        }

        if (requestParameters['updateVariableExpressionPeriodParams'] == null) {
            throw new runtime.RequiredError(
                'updateVariableExpressionPeriodParams',
                'Required parameter "updateVariableExpressionPeriodParams" was null or undefined when calling updateExpressionPeriodEnd().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/variable/{variable_uuid}/expressions/{expression_uuid}/period`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"expression_uuid"}}`, encodeURIComponent(String(requestParameters['expressionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateVariableExpressionPeriodParamsToJSON(requestParameters['updateVariableExpressionPeriodParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Update Expression Period End
     */
    async updateExpressionPeriodEnd(requestParameters: UpdateExpressionPeriodEndRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.updateExpressionPeriodEndRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model Contributors
     */
    async updateModelContributorsRaw(requestParameters: UpdateModelContributorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateModelContributors().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateModelContributors().'
            );
        }

        if (requestParameters['updateModelContributorsParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelContributorsParams',
                'Required parameter "updateModelContributorsParams" was null or undefined when calling updateModelContributors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/contributors`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelContributorsParamsToJSON(requestParameters['updateModelContributorsParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Update Model Contributors
     */
    async updateModelContributors(requestParameters: UpdateModelContributorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.updateModelContributorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model Dimension Values
     */
    async updateModelDimensionValuesRaw(requestParameters: UpdateModelDimensionValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateModelDimensionValues().'
            );
        }

        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateModelDimensionValues().'
            );
        }

        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling updateModelDimensionValues().'
            );
        }

        if (requestParameters['updateModelDimensionValuesParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelDimensionValuesParams',
                'Required parameter "updateModelDimensionValuesParams" was null or undefined when calling updateModelDimensionValues().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/models/{model_uuid}/dimensions/{dimension_uuid}`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelDimensionValuesParamsToJSON(requestParameters['updateModelDimensionValuesParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Model Dimension Values
     */
    async updateModelDimensionValues(requestParameters: UpdateModelDimensionValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateModelDimensionValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model Period
     */
    async updateModelPeriodRaw(requestParameters: UpdateModelPeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateModelPeriod().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateModelPeriod().'
            );
        }

        if (requestParameters['updateModelPeriodParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelPeriodParams',
                'Required parameter "updateModelPeriodParams" was null or undefined when calling updateModelPeriod().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/period`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelPeriodParamsToJSON(requestParameters['updateModelPeriodParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Update Model Period
     */
    async updateModelPeriod(requestParameters: UpdateModelPeriodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.updateModelPeriodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model Period Grain
     */
    async updateModelPeriodGrainRaw(requestParameters: UpdateModelPeriodGrainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateModelPeriodGrain().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateModelPeriodGrain().'
            );
        }

        if (requestParameters['updateModelPeriodGrainParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelPeriodGrainParams',
                'Required parameter "updateModelPeriodGrainParams" was null or undefined when calling updateModelPeriodGrain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/period_grain`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelPeriodGrainParamsToJSON(requestParameters['updateModelPeriodGrainParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Update Model Period Grain
     */
    async updateModelPeriodGrain(requestParameters: UpdateModelPeriodGrainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.updateModelPeriodGrainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model
     */
    async updateModelPropertiesRaw(requestParameters: UpdateModelPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateModelProperties().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateModelProperties().'
            );
        }

        if (requestParameters['updateModelParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelParams',
                'Required parameter "updateModelParams" was null or undefined when calling updateModelProperties().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelParamsToJSON(requestParameters['updateModelParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Update Model
     */
    async updateModelProperties(requestParameters: UpdateModelPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.updateModelPropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model Variable
     */
    async updateModelVariableRaw(requestParameters: UpdateModelVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateModelVariable().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling updateModelVariable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateModelVariable().'
            );
        }

        if (requestParameters['updateModelVariableParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelVariableParams',
                'Required parameter "updateModelVariableParams" was null or undefined when calling updateModelVariable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/variable/{variable_uuid}`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelVariableParamsToJSON(requestParameters['updateModelVariableParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Model Variable
     */
    async updateModelVariable(requestParameters: UpdateModelVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateModelVariableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model Version Details
     */
    async updateModelVersionRaw(requestParameters: UpdateModelVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateModelVersion().'
            );
        }

        if (requestParameters['versionUuid'] == null) {
            throw new runtime.RequiredError(
                'versionUuid',
                'Required parameter "versionUuid" was null or undefined when calling updateModelVersion().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateModelVersion().'
            );
        }

        if (requestParameters['updateModelVersionParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelVersionParams',
                'Required parameter "updateModelVersionParams" was null or undefined when calling updateModelVersion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/versions/{version_uuid}`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"version_uuid"}}`, encodeURIComponent(String(requestParameters['versionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelVersionParamsToJSON(requestParameters['updateModelVersionParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Model Version Details
     */
    async updateModelVersion(requestParameters: UpdateModelVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateModelVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model Version Contributors
     */
    async updateModelVersionContributorsRaw(requestParameters: UpdateModelVersionContributorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateModelVersionContributors().'
            );
        }

        if (requestParameters['versionUuid'] == null) {
            throw new runtime.RequiredError(
                'versionUuid',
                'Required parameter "versionUuid" was null or undefined when calling updateModelVersionContributors().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateModelVersionContributors().'
            );
        }

        if (requestParameters['updateModelVersionContributorsParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelVersionContributorsParams',
                'Required parameter "updateModelVersionContributorsParams" was null or undefined when calling updateModelVersionContributors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/versions/{version_uuid}/contributors`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"version_uuid"}}`, encodeURIComponent(String(requestParameters['versionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelVersionContributorsParamsToJSON(requestParameters['updateModelVersionContributorsParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Update Model Version Contributors
     */
    async updateModelVersionContributors(requestParameters: UpdateModelVersionContributorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.updateModelVersionContributorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Model Variable Dimension Value
     */
    async updateVariableDimensionValueRaw(requestParameters: UpdateVariableDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Variable>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateVariableDimensionValue().'
            );
        }

        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling updateVariableDimensionValue().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling updateVariableDimensionValue().'
            );
        }

        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling updateVariableDimensionValue().'
            );
        }

        if (requestParameters['updateModelVariableDimensionValueParams'] == null) {
            throw new runtime.RequiredError(
                'updateModelVariableDimensionValueParams',
                'Required parameter "updateModelVariableDimensionValueParams" was null or undefined when calling updateVariableDimensionValue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/models/{model_uuid}/variables/{variable_uuid}/dimensions/{dimension_uuid}`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateModelVariableDimensionValueParamsToJSON(requestParameters['updateModelVariableDimensionValueParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableFromJSON(jsonValue));
    }

    /**
     * Update Model Variable Dimension Value
     */
    async updateVariableDimensionValue(requestParameters: UpdateVariableDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Variable> {
        const response = await this.updateVariableDimensionValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate Model Variable Expression
     */
    async validateExpressionRaw(requestParameters: ValidateExpressionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['modelUuid'] == null) {
            throw new runtime.RequiredError(
                'modelUuid',
                'Required parameter "modelUuid" was null or undefined when calling validateExpression().'
            );
        }

        if (requestParameters['variableUuid'] == null) {
            throw new runtime.RequiredError(
                'variableUuid',
                'Required parameter "variableUuid" was null or undefined when calling validateExpression().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling validateExpression().'
            );
        }

        if (requestParameters['validateExpressionParams'] == null) {
            throw new runtime.RequiredError(
                'validateExpressionParams',
                'Required parameter "validateExpressionParams" was null or undefined when calling validateExpression().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/models/{model_uuid}/variable/{variable_uuid}/validate`.replace(`{${"model_uuid"}}`, encodeURIComponent(String(requestParameters['modelUuid']))).replace(`{${"variable_uuid"}}`, encodeURIComponent(String(requestParameters['variableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateExpressionParamsToJSON(requestParameters['validateExpressionParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Validate Model Variable Expression
     */
    async validateExpression(requestParameters: ValidateExpressionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.validateExpressionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
