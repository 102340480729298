/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TableAttributes } from './TableAttributes';
import {
    TableAttributesFromJSON,
    TableAttributesFromJSONTyped,
    TableAttributesToJSON,
} from './TableAttributes';
import type { TableEntity } from './TableEntity';
import {
    TableEntityFromJSON,
    TableEntityFromJSONTyped,
    TableEntityToJSON,
} from './TableEntity';

/**
 * 
 * @export
 * @interface Table
 */
export interface Table {
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    dashboardUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    workspaceUuid: string;
    /**
     * 
     * @type {Array<TableEntity>}
     * @memberof Table
     */
    entities: Array<TableEntity>;
    /**
     * 
     * @type {TableAttributes}
     * @memberof Table
     */
    attributes: TableAttributes;
}

/**
 * Check if a given object implements the Table interface.
 */
export function instanceOfTable(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('dashboardUuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('entities' in value)) return false;
    if (!('attributes' in value)) return false;
    return true;
}

export function TableFromJSON(json: any): Table {
    return TableFromJSONTyped(json, false);
}

export function TableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Table {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'dashboardUuid': json['dashboard_uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'entities': ((json['entities'] as Array<any>).map(TableEntityFromJSON)),
        'attributes': TableAttributesFromJSON(json['attributes']),
    };
}

export function TableToJSON(value?: Table | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'dashboard_uuid': value['dashboardUuid'],
        'workspace_uuid': value['workspaceUuid'],
        'entities': ((value['entities'] as Array<any>).map(TableEntityToJSON)),
        'attributes': TableAttributesToJSON(value['attributes']),
    };
}

