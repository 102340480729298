/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateModelParams
 */
export interface UpdateModelParams {
    /**
     * 
     * @type {object}
     * @memberof UpdateModelParams
     */
    updates: object;
}

/**
 * Check if a given object implements the UpdateModelParams interface.
 */
export function instanceOfUpdateModelParams(value: object): boolean {
    if (!('updates' in value)) return false;
    return true;
}

export function UpdateModelParamsFromJSON(json: any): UpdateModelParams {
    return UpdateModelParamsFromJSONTyped(json, false);
}

export function UpdateModelParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelParams {
    if (json == null) {
        return json;
    }
    return {
        
        'updates': json['updates'],
    };
}

export function UpdateModelParamsToJSON(value?: UpdateModelParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'updates': value['updates'],
    };
}

