/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetVariableDimensionsParams
 */
export interface SetVariableDimensionsParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof SetVariableDimensionsParams
     */
    dimensions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetVariableDimensionsParams
     */
    disabledDimensions: Array<string>;
}

/**
 * Check if a given object implements the SetVariableDimensionsParams interface.
 */
export function instanceOfSetVariableDimensionsParams(value: object): boolean {
    if (!('dimensions' in value)) return false;
    if (!('disabledDimensions' in value)) return false;
    return true;
}

export function SetVariableDimensionsParamsFromJSON(json: any): SetVariableDimensionsParams {
    return SetVariableDimensionsParamsFromJSONTyped(json, false);
}

export function SetVariableDimensionsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetVariableDimensionsParams {
    if (json == null) {
        return json;
    }
    return {
        
        'dimensions': json['dimensions'],
        'disabledDimensions': json['disabled_dimensions'],
    };
}

export function SetVariableDimensionsParamsToJSON(value?: SetVariableDimensionsParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dimensions': value['dimensions'],
        'disabled_dimensions': value['disabledDimensions'],
    };
}

