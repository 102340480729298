/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TableEntity
 */
export interface TableEntity {
    /**
     * 
     * @type {string}
     * @memberof TableEntity
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof TableEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TableEntity
     */
    entityType: TableEntityEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TableEntity
     */
    tableUuid: string;
}


/**
 * @export
 */
export const TableEntityEntityTypeEnum = {
    Metric: 'metric',
    Variable: 'variable'
} as const;
export type TableEntityEntityTypeEnum = typeof TableEntityEntityTypeEnum[keyof typeof TableEntityEntityTypeEnum];


/**
 * Check if a given object implements the TableEntity interface.
 */
export function instanceOfTableEntity(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('entityType' in value)) return false;
    if (!('tableUuid' in value)) return false;
    return true;
}

export function TableEntityFromJSON(json: any): TableEntity {
    return TableEntityFromJSONTyped(json, false);
}

export function TableEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TableEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'entityType': json['entity_type'],
        'tableUuid': json['table_uuid'],
    };
}

export function TableEntityToJSON(value?: TableEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'entity_type': value['entityType'],
        'table_uuid': value['tableUuid'],
    };
}

