/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersInner } from './FiltersInner';
import {
    FiltersInnerFromJSON,
    FiltersInnerFromJSONTyped,
    FiltersInnerToJSON,
} from './FiltersInner';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface TableAttributes
 */
export interface TableAttributes {
    /**
     * 
     * @type {string}
     * @memberof TableAttributes
     */
    type?: TableAttributesTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TableAttributes
     */
    title?: string;
    /**
     * 
     * @type {Array<FiltersInner>}
     * @memberof TableAttributes
     */
    filters?: Array<FiltersInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TableAttributes
     */
    dimensions?: Array<string>;
    /**
     * 
     * @type {Period}
     * @memberof TableAttributes
     */
    period?: Period;
    /**
     * 
     * @type {Date}
     * @memberof TableAttributes
     */
    periodSeparator?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof TableAttributes
     */
    scenarios?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TableAttributes
     */
    metrics?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TableAttributes
     */
    entities?: Array<string>;
    /**
     * 
     * @type {Array<any>}
     * @memberof TableAttributes
     */
    structure?: Array<any>;
    /**
     * 
     * @type {object}
     * @memberof TableAttributes
     */
    metricsDimensions?: object;
    /**
     * 
     * @type {string}
     * @memberof TableAttributes
     */
    yearTotals?: TableAttributesYearTotalsEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TableAttributes
     */
    rowDimensions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TableAttributes
     */
    colDimensions?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TableAttributes
     */
    includePeriod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TableAttributes
     */
    removeColSubtotals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TableAttributes
     */
    totalDeviation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TableAttributes
     */
    percentageDeviation?: boolean;
}


/**
 * @export
 */
export const TableAttributesTypeEnum = {
    Empty: '',
    PivotTable: 'pivotTable',
    ReportTable: 'reportTable',
    Comparative: 'comparative',
    Projection: 'projection',
    Histogram: 'histogram',
    Pie: 'pie',
    Treemap: 'treemap',
    Bridge: 'bridge',
    Model: 'model',
    ModelingTable: 'modelingTable'
} as const;
export type TableAttributesTypeEnum = typeof TableAttributesTypeEnum[keyof typeof TableAttributesTypeEnum];

/**
 * @export
 */
export const TableAttributesYearTotalsEnum = {
    Natural: 'natural',
    Fiscal: 'fiscal',
    Null: 'null'
} as const;
export type TableAttributesYearTotalsEnum = typeof TableAttributesYearTotalsEnum[keyof typeof TableAttributesYearTotalsEnum];


/**
 * Check if a given object implements the TableAttributes interface.
 */
export function instanceOfTableAttributes(value: object): boolean {
    return true;
}

export function TableAttributesFromJSON(json: any): TableAttributes {
    return TableAttributesFromJSONTyped(json, false);
}

export function TableAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TableAttributes {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'filters': json['filters'] == null ? undefined : ((json['filters'] as Array<any>).map(FiltersInnerFromJSON)),
        'dimensions': json['dimensions'] == null ? undefined : json['dimensions'],
        'period': json['period'] == null ? undefined : PeriodFromJSON(json['period']),
        'periodSeparator': json['period_separator'] == null ? undefined : (new Date(json['period_separator'])),
        'scenarios': json['scenarios'] == null ? undefined : json['scenarios'],
        'metrics': json['metrics'] == null ? undefined : json['metrics'],
        'entities': json['entities'] == null ? undefined : json['entities'],
        'structure': json['structure'] == null ? undefined : json['structure'],
        'metricsDimensions': json['metrics_dimensions'] == null ? undefined : json['metrics_dimensions'],
        'yearTotals': json['year_totals'] == null ? undefined : json['year_totals'],
        'rowDimensions': json['row_dimensions'] == null ? undefined : json['row_dimensions'],
        'colDimensions': json['col_dimensions'] == null ? undefined : json['col_dimensions'],
        'includePeriod': json['include_period'] == null ? undefined : json['include_period'],
        'removeColSubtotals': json['remove_col_subtotals'] == null ? undefined : json['remove_col_subtotals'],
        'totalDeviation': json['total_deviation'] == null ? undefined : json['total_deviation'],
        'percentageDeviation': json['percentage_deviation'] == null ? undefined : json['percentage_deviation'],
    };
}

export function TableAttributesToJSON(value?: TableAttributes | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'title': value['title'],
        'filters': value['filters'] == null ? undefined : ((value['filters'] as Array<any>).map(FiltersInnerToJSON)),
        'dimensions': value['dimensions'],
        'period': PeriodToJSON(value['period']),
        'period_separator': value['periodSeparator'] == null ? undefined : ((value['periodSeparator'] as any).toISOString()),
        'scenarios': value['scenarios'],
        'metrics': value['metrics'],
        'entities': value['entities'],
        'structure': value['structure'],
        'metrics_dimensions': value['metricsDimensions'],
        'year_totals': value['yearTotals'],
        'row_dimensions': value['rowDimensions'],
        'col_dimensions': value['colDimensions'],
        'include_period': value['includePeriod'],
        'remove_col_subtotals': value['removeColSubtotals'],
        'total_deviation': value['totalDeviation'],
        'percentage_deviation': value['percentageDeviation'],
    };
}

