/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateDatabaseParams,
  Database,
  DownloadCSVParams,
  FetchDatabaseParams,
  HTTPValidationError,
  OverviewTableData,
  UpdateDatabaseOwnersParams,
  UpdateDatabaseParams,
  UpdateDatabaseRelationshipsParams,
  UpdateDatabaseSchemaParams,
} from '../models/index';
import {
    CreateDatabaseParamsFromJSON,
    CreateDatabaseParamsToJSON,
    DatabaseFromJSON,
    DatabaseToJSON,
    DownloadCSVParamsFromJSON,
    DownloadCSVParamsToJSON,
    FetchDatabaseParamsFromJSON,
    FetchDatabaseParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    OverviewTableDataFromJSON,
    OverviewTableDataToJSON,
    UpdateDatabaseOwnersParamsFromJSON,
    UpdateDatabaseOwnersParamsToJSON,
    UpdateDatabaseParamsFromJSON,
    UpdateDatabaseParamsToJSON,
    UpdateDatabaseRelationshipsParamsFromJSON,
    UpdateDatabaseRelationshipsParamsToJSON,
    UpdateDatabaseSchemaParamsFromJSON,
    UpdateDatabaseSchemaParamsToJSON,
} from '../models/index';

export interface CreateDatabaseRequest {
    workspaceCode: string;
    createDatabaseParams: CreateDatabaseParams;
}

export interface DatabaseDataframeRequest {
    workspaceCode: string;
    databaseUuid: string;
    fetchDatabaseParams: FetchDatabaseParams;
}

export interface DeleteDatabaseRequest {
    workspaceCode: string;
    databaseUuid: string;
}

export interface DownloadDatabaseAsCsvRequest {
    databaseUuid: string;
    workspaceCode: string;
    downloadCSVParams: DownloadCSVParams;
}

export interface FetchDatabaseStatusRequest {
    workspaceCode: string;
    databaseUuid: string;
}

export interface ProcessDatabaseRequest {
    workspaceCode: string;
    databaseUuid: string;
}

export interface UpdateDatabaseRequest {
    workspaceCode: string;
    databaseUuid: string;
    updateDatabaseParams: UpdateDatabaseParams;
}

export interface UpdateDatabaseOwnersRequest {
    workspaceCode: string;
    databaseUuid: string;
    updateDatabaseOwnersParams: UpdateDatabaseOwnersParams;
}

export interface UpdateDatabaseRelationshipsRequest {
    workspaceCode: string;
    databaseUuid: string;
    updateDatabaseRelationshipsParams: UpdateDatabaseRelationshipsParams;
}

export interface UpdateDatabaseSchemaRequest {
    workspaceCode: string;
    databaseUuid: string;
    updateDatabaseSchemaParams: UpdateDatabaseSchemaParams;
}

export interface WorkspaceDatabsesRequest {
    workspaceCode: string;
}

/**
 * 
 */
export class DatabasesApi extends runtime.BaseAPI {

    /**
     * Create a database.
     * Create Database
     */
    async createDatabaseRaw(requestParameters: CreateDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Database>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createDatabase().'
            );
        }

        if (requestParameters['createDatabaseParams'] == null) {
            throw new runtime.RequiredError(
                'createDatabaseParams',
                'Required parameter "createDatabaseParams" was null or undefined when calling createDatabase().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDatabaseParamsToJSON(requestParameters['createDatabaseParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseFromJSON(jsonValue));
    }

    /**
     * Create a database.
     * Create Database
     */
    async createDatabase(requestParameters: CreateDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Database> {
        const response = await this.createDatabaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a dataframe for a database.
     * Database Dataframe
     */
    async databaseDataframeRaw(requestParameters: DatabaseDataframeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OverviewTableData>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling databaseDataframe().'
            );
        }

        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling databaseDataframe().'
            );
        }

        if (requestParameters['fetchDatabaseParams'] == null) {
            throw new runtime.RequiredError(
                'fetchDatabaseParams',
                'Required parameter "fetchDatabaseParams" was null or undefined when calling databaseDataframe().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}/df`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchDatabaseParamsToJSON(requestParameters['fetchDatabaseParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OverviewTableDataFromJSON(jsonValue));
    }

    /**
     * Get a dataframe for a database.
     * Database Dataframe
     */
    async databaseDataframe(requestParameters: DatabaseDataframeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OverviewTableData> {
        const response = await this.databaseDataframeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a database.
     * Delete Database
     */
    async deleteDatabaseRaw(requestParameters: DeleteDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteDatabase().'
            );
        }

        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling deleteDatabase().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete a database.
     * Delete Database
     */
    async deleteDatabase(requestParameters: DeleteDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDatabaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download Database As Csv
     */
    async downloadDatabaseAsCsvRaw(requestParameters: DownloadDatabaseAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling downloadDatabaseAsCsv().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadDatabaseAsCsv().'
            );
        }

        if (requestParameters['downloadCSVParams'] == null) {
            throw new runtime.RequiredError(
                'downloadCSVParams',
                'Required parameter "downloadCSVParams" was null or undefined when calling downloadDatabaseAsCsv().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}/csv`.replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadCSVParamsToJSON(requestParameters['downloadCSVParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download Database As Csv
     */
    async downloadDatabaseAsCsv(requestParameters: DownloadDatabaseAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadDatabaseAsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a database status.
     * Database Status
     */
    async fetchDatabaseStatusRaw(requestParameters: FetchDatabaseStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchDatabaseStatus().'
            );
        }

        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling fetchDatabaseStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}/status`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get a database status.
     * Database Status
     */
    async fetchDatabaseStatus(requestParameters: FetchDatabaseStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.fetchDatabaseStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process a database.
     * Process Database
     */
    async processDatabaseRaw(requestParameters: ProcessDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling processDatabase().'
            );
        }

        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling processDatabase().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}/process`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Process a database.
     * Process Database
     */
    async processDatabase(requestParameters: ProcessDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.processDatabaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a database.
     * Update Database
     */
    async updateDatabaseRaw(requestParameters: UpdateDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Database>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateDatabase().'
            );
        }

        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling updateDatabase().'
            );
        }

        if (requestParameters['updateDatabaseParams'] == null) {
            throw new runtime.RequiredError(
                'updateDatabaseParams',
                'Required parameter "updateDatabaseParams" was null or undefined when calling updateDatabase().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDatabaseParamsToJSON(requestParameters['updateDatabaseParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseFromJSON(jsonValue));
    }

    /**
     * Update a database.
     * Update Database
     */
    async updateDatabase(requestParameters: UpdateDatabaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Database> {
        const response = await this.updateDatabaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a database owners.
     * Update Database Owners
     */
    async updateDatabaseOwnersRaw(requestParameters: UpdateDatabaseOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Database>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateDatabaseOwners().'
            );
        }

        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling updateDatabaseOwners().'
            );
        }

        if (requestParameters['updateDatabaseOwnersParams'] == null) {
            throw new runtime.RequiredError(
                'updateDatabaseOwnersParams',
                'Required parameter "updateDatabaseOwnersParams" was null or undefined when calling updateDatabaseOwners().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}/owners`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDatabaseOwnersParamsToJSON(requestParameters['updateDatabaseOwnersParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseFromJSON(jsonValue));
    }

    /**
     * Update a database owners.
     * Update Database Owners
     */
    async updateDatabaseOwners(requestParameters: UpdateDatabaseOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Database> {
        const response = await this.updateDatabaseOwnersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a database relationships.
     * Update Database Relationships
     */
    async updateDatabaseRelationshipsRaw(requestParameters: UpdateDatabaseRelationshipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Database>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateDatabaseRelationships().'
            );
        }

        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling updateDatabaseRelationships().'
            );
        }

        if (requestParameters['updateDatabaseRelationshipsParams'] == null) {
            throw new runtime.RequiredError(
                'updateDatabaseRelationshipsParams',
                'Required parameter "updateDatabaseRelationshipsParams" was null or undefined when calling updateDatabaseRelationships().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}/relationships`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDatabaseRelationshipsParamsToJSON(requestParameters['updateDatabaseRelationshipsParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseFromJSON(jsonValue));
    }

    /**
     * Update a database relationships.
     * Update Database Relationships
     */
    async updateDatabaseRelationships(requestParameters: UpdateDatabaseRelationshipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Database> {
        const response = await this.updateDatabaseRelationshipsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a database schema.
     * Update Database Schema
     */
    async updateDatabaseSchemaRaw(requestParameters: UpdateDatabaseSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateDatabaseSchema().'
            );
        }

        if (requestParameters['databaseUuid'] == null) {
            throw new runtime.RequiredError(
                'databaseUuid',
                'Required parameter "databaseUuid" was null or undefined when calling updateDatabaseSchema().'
            );
        }

        if (requestParameters['updateDatabaseSchemaParams'] == null) {
            throw new runtime.RequiredError(
                'updateDatabaseSchemaParams',
                'Required parameter "updateDatabaseSchemaParams" was null or undefined when calling updateDatabaseSchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases/{database_uuid}/schema`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"database_uuid"}}`, encodeURIComponent(String(requestParameters['databaseUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDatabaseSchemaParamsToJSON(requestParameters['updateDatabaseSchemaParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update a database schema.
     * Update Database Schema
     */
    async updateDatabaseSchema(requestParameters: UpdateDatabaseSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateDatabaseSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all databases in a workspace.
     * Workspace Databases
     */
    async workspaceDatabsesRaw(requestParameters: WorkspaceDatabsesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Database>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling workspaceDatabses().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/databases`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatabaseFromJSON));
    }

    /**
     * Find all databases in a workspace.
     * Workspace Databases
     */
    async workspaceDatabses(requestParameters: WorkspaceDatabsesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Database>> {
        const response = await this.workspaceDatabsesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
