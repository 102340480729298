/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    type: AttachmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    entityUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    createdByUuid: string;
    /**
     * 
     * @type {Date}
     * @memberof Attachment
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Attachment
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const AttachmentTypeEnum = {
    File: 'file',
    Link: 'link'
} as const;
export type AttachmentTypeEnum = typeof AttachmentTypeEnum[keyof typeof AttachmentTypeEnum];


/**
 * Check if a given object implements the Attachment interface.
 */
export function instanceOfAttachment(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    if (!('source' in value)) return false;
    if (!('entityUuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('createdByUuid' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'type': json['type'],
        'source': json['source'],
        'entityUuid': json['entity_uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'createdByUuid': json['created_by_uuid'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'description': value['description'],
        'type': value['type'],
        'source': value['source'],
        'entity_uuid': value['entityUuid'],
        'workspace_uuid': value['workspaceUuid'],
        'created_by_uuid': value['createdByUuid'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
    };
}

